import React, { ReactElement } from "react";
import Image from "next/image";

export default function SystemBenefits(): ReactElement {
    return (
        <section className="flex flex-col lg:flex-row  gap-10 lg:gap-0 lg:mt-32 mt-16  ">
            <div className="max-w-[579px]  mx-auto lg:mx-0 ">
                <h2 className="font-bold lg:text-3xl text-xl lg:leading-[45px] text-[#1A7184]">
                    How Can a POS System Benefit My Business?
                </h2>
                <p className="mt-5   lg:text-lg text-base lg:leading-7 font-light">
                    A POS system serves as a central hub that plays a vital role
                    in simplifying, enhancing, and expediting business
                    operations. It acts as a comprehensive solution that can
                    effectively manage sales, inventory, customer data, and
                    employee schedules, consolidating these critical aspects of
                    a business into one convenient platform.
                </p>
                <p className="mt-7 lg:text-lg text-base lg:leading-7 font-light">
                    By providing accurate sales data and efficient inventory
                    management, a POS system empowers businesses to make
                    informed decisions, optimize operations, and reduce costs.
                    Additionally, POS systems offer valuable marketing and
                    customer relationship management tools, enabling businesses
                    to stay connected with their customer base and drive sales.
                    Ultimately, a POS system is indispensable for any business
                    aiming to streamline operations, improve efficiency, and
                    maximize profitability.
                </p>
                <span className="w-[150px] h-[4px] block bg-[#F38830] mt-10"></span>
            </div>
            <div className="w-[240px] h-[240px] md:w-[400px] md:h-[400px] lg:h-[575px] lg:w-[575px] lg:min-w-[575px] relative mx-auto lg:mx-0  ">
                <Image
                    src={
                        "/pointofsaleprices/assets/images/systemBenefitsImg.png"
                    }
                    alt="image"
                    layout="fill"
                />
            </div>
        </section>
    );
}
